import { instance, instanceWithAuth, multipartInstanceWithAuth } from "@/api/index";

function loginUser(userData) {
  return instance.post("/auth/login", userData, { withCredentials: true });
}

function loginExtension() {
  return instanceWithAuth.get("/users/login-extension");
}

function signUp(signUpdata) {
  return instance.post("users/sign-up", signUpdata)
}

function signUpBizNoUpload(signUpBizNoRequest) {
  return instance.post("users/sign-up/biz-uploads", signUpBizNoRequest)
}

function refreshTokenUser() {
  return instance.get("/auth/refresh", { withCredentials: true });
}

function findId(findIdData) {
  return instance.post("/users/find-id", findIdData);
}

function findPwd(findPwdData) {
  return instance.post("/users/find-pwd", findPwdData);
}

function updatePwd(updatePwdData) {
  return instanceWithAuth.post("/users/update-pwd", updatePwdData);
}

function userUpdatePwd(userUpdatePwd) {
  return instance.post("/users/user-update-pwd", userUpdatePwd);
}

function duplicateIdCheck(userData) {
  return instance.get("/users/id-check", { params: userData });
}

function duplicateEmlCheck(userData) {
  return instance.get("/users/email-check", { params: userData });
}

function duplicateTelNoCheck(userData) {
  return instance.get("/users/tel-no-check", { params: userData });
}

function updateMyInfo(updateUserInfo) {
  return instanceWithAuth.post("/users/update-info", updateUserInfo);
}

function searchAddress(searchData) {
  return instance.get("/users/search-address", { params: searchData });
}

function getUserCmpInfo(data) {
  return instance.get("/users/cmp-info", { params: data });
}

function getUserInfo(data) {
  return instanceWithAuth.get("/users/get-user-info", { params: data });
}

function getMyFiles() {
  return instanceWithAuth.get("/users/get-files");
}

function deleteMyFiles(seqList) {
  return instanceWithAuth.post("/users/delete-files", seqList);
}

function downloadMyFiles(downloadFileData){
  return instanceWithAuth.get("/users/download-files",{ params: downloadFileData });
}

function uploadMyFiles(uploadFileRequest) {
  return instanceWithAuth.post("/users/upload-files", uploadFileRequest);
}

function getCountryCodes() {
  return instanceWithAuth.get("/users/country-codes");
}

function withdrawUser() {
  return instanceWithAuth.post("/users/withdraw");
}
function testApi() {
  return instanceWithAuth.get("/auth/test");
}
function nicePass() {
  return instanceWithAuth.get("/nice/enc");
}

function ssoLogin(params) {
  return instance.get("/auth/sso/login", { params: params });
}
function ssoRegisterCmp(formData) {
  return multipartInstanceWithAuth.post("/users/sso/cmp-register", formData);
}

export {
  loginUser, loginExtension, signUp, signUpBizNoUpload, refreshTokenUser, findId, findPwd, userUpdatePwd, updatePwd, testApi, duplicateIdCheck,
  duplicateEmlCheck, duplicateTelNoCheck, updateMyInfo, searchAddress, getUserCmpInfo, getUserInfo, nicePass, withdrawUser, getMyFiles
  , deleteMyFiles, uploadMyFiles,downloadMyFiles, getCountryCodes, ssoLogin, ssoRegisterCmp
};

